var toggle = function(e) {
  document.body.classList.toggle('search-active');

  if (document.body.classList.contains('search-active')) {
    document.querySelector('.search__input').focus();
    this.setAttribute('aria-expanded', true);
  }
  else {
    this.setAttribute('aria-expanded', false);
  }
  e.preventDefault();
};

var targets = document.querySelectorAll('.toggle-search');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', toggle);
}
