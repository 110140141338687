// Autoplay video no supported in iOS devices so hide
var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
var android = /Android/.test(navigator.userAgent);


if(iOS || android) {

  if (document.querySelector('.overlay__video')!=null) {
    document.querySelector('.overlay__image').style.display = 'block';
    document.querySelector('.overlay__video__media').style.display = 'none'
  }

}

