var toggle = function(e) {
  this.parentNode.classList.toggle('dropdown--active');
  e.preventDefault();

  if (this.parentNode.classList.contains('dropdown--active')) {
    this.setAttribute('aria-expanded', true);
    this.nextElementSibling.setAttribute('aria-hidden', false);
  }
  else {
    this.setAttribute('aria-expanded', false);
    this.nextElementSibling.setAttribute('aria-hidden', true);
  }
};

var listen = function(e) {
  var active = document.querySelectorAll('.dropdown--active');
  for (var i = 0; i < active.length; i += 1) {
    if (!active[i].contains(e.target)) {
      active[i].classList.remove('dropdown--active');
      active[i].children[1].setAttribute('aria-hidden', true);
    }
  }
};

var targets = document.querySelectorAll('.dropdown__toggle');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', toggle);
}

window.addEventListener('click', listen);
