var Flickity = require('flickity');
require('flickity-imagesloaded');

var sliders = document.querySelectorAll('.image-slider');

for (i = 0; i < sliders.length; i += 1) {
  var slider = new Flickity(sliders[i], {
    cellAlign: 'left',
    contain: true,
    imagesLoaded: true,
    arrowShape: 'M64.2,88.1c-0.6,0-1.2-0.2-1.7-0.7L27,51.8c-0.9-0.9-0.9-2.4,0-3.4l35.6-35.6c0.9-0.9,2.4-0.9,3.4,0s0.9,2.4,0,3.4L32,50.1 L65.9,84c0.9,0.9,0.9,2.4,0,3.4C65.4,87.8,64.8,88.1,64.2,88.1z'
  });
}
