


var click = function() {
  var selectedToggle = document.querySelector('.form__radio[data-toggle] input:checked');
  var value = selectedToggle.value;

  var allDropdowns = document.querySelectorAll('.toggle__form__dropdown[data-toggle');
  var selectedDropdown = document.querySelector('.toggle__form__dropdown[data-toggle="' + value + '"]');

  [].forEach.call(allDropdowns, function(el) {
    el.classList.remove('active');
  });

  selectedDropdown.classList.add('active');

}

var inputs = document.querySelectorAll('.form__radio[data-toggle] input');

for (var i = 0; i < inputs.length; i += 1) {
  var input = inputs[i];
  input.addEventListener('click', click);
}