var update = function() {
  var text = this.options[this.selectedIndex].text;
  var container = this.previousSibling;
  container.innerHTML = text;
};

var selects = document.querySelectorAll('.form__select select');

for (var i = 0; i < selects.length; i += 1) {
  update.call(selects[i]);
  selects[i].addEventListener('change', update);
  selects[i].addEventListener('focus', function(e) {
    this.previousSibling.classList.add('form__select__text--focus');
  });
  selects[i].addEventListener('blur', function(e) {
    this.previousSibling.classList.remove('form__select__text--focus');
  });
}
