var Flickity = require('flickity');
require('flickity-imagesloaded');

var sliders = document.querySelectorAll('.hero-slider');

for (i = 0; i < sliders.length; i += 1) {
  var sliderContainer = sliders[i];

  var items = [];

  var numItems = sliderContainer.children.length;

  for (j = 0; j < numItems; j += 1) {
    items.push(sliderContainer.children[0]);
    sliderContainer.removeChild(sliderContainer.children[0]);
  }

  while (items.length > 0) {
    var randIdx = Math.floor(Math.random() * items.length);

    sliderContainer.appendChild(items[randIdx]);

    items[randIdx] = null;

    items = items.filter(function(i) { return i; });
  }

  var slider = new Flickity(sliders[i], {
    imagesLoaded: true,
    prevNextButtons: false
  });


  function addFocusSelectHandlerToElem(elem, cellIdx) {
    elem.setAttribute('cellIdx', cellIdx);
    elem.onfocus = function(e) {
      var selectedIndex = e.currentTarget.getAttribute('cellIdx');

      if (selectedIndex) {
        if (selectedIndex === '1') {
          slider.select(2, false, true);
          window.setTimeout(function() { slider.select(1, false, true); }, 0);
        }
        else {
          slider.select(selectedIndex, false, true);
        }
      }
    }
  }

  function addFocusSelectHandlerToElems(elems, cellIdx) {
    for (elemIdx = 0; elemIdx < elems.length; elemIdx += 1) {
      addFocusSelectHandlerToElem(elems[elemIdx], cellIdx);
    }
  }

  // Go through each slider cell
  for (cellIdx = 0; cellIdx < slider.cells.length; cellIdx += 1) {
    addFocusSelectHandlerToElems(
      slider.cells[cellIdx].element.querySelectorAll('a'),
      cellIdx
    );

  }

  var vid = document.querySelector('.hero--video');

  if (vid != null) {
    var videoAll = document.querySelectorAll('.hero--video video');
    
    var check = function(video, source) {
      if (window.innerWidth >= 768) {
        video.pause();
        source.setAttribute('src', source.getAttribute('data-src'));
        video.load();
        video.play();
      } else {
        
        requestAnimationFrame(function() {
          check(video, source);
        });
      }
    };
    Array.prototype.forEach.call(function(videoAll, video) {
      var source = video.querySelector('source');
      check(video, source);
    });

  }
}


var imageSliders = document.querySelectorAll(".hero-slider-alt");

for (i = 0; i < imageSliders.length; i += 1) {
  var imageSlider = new Flickity(imageSliders[i], {
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: false,
    autoPlay: 4000,
    draggable: false,
    setGallerySize: false,
    cellSelector: '.hero-slider-alt__image',
    pauseAutoPlayOnHover: false
  });

  var sliderControl = imageSliders[i].querySelector(".hero-slider-alt__control");
  
  sliderControl.addEventListener('click', function() {
    
    if (imageSlider.player.state === "playing") {
      imageSlider.pausePlayer();
      this.classList.add('paused');
    }
    else {
      imageSlider.playPlayer();
      this.classList.remove('paused');
    }
  })


}
