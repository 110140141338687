var tab = document.querySelector('[role="tab"]');

function changeTabs(e) {
  var target = e.target;
  var parent = target.parentNode;
  var grandparent = parent.parentNode; // Remove all current selected tabs

  Array.prototype.forEach.call(parent.querySelectorAll('[aria-selected="true"]'), function(t) {
    return t.setAttribute("aria-selected", false);
  });

  target.setAttribute("aria-selected", true); // Hide all tab panels

  Array.prototype.forEach.call(grandparent.querySelectorAll('[role="tabpanel"]'), function(p) {
    return p.setAttribute("hidden", true);
  }); // Show the selected panel

  grandparent.parentNode.querySelector("#".concat(target.getAttribute("aria-controls"))).removeAttribute("hidden");
}

if (tab) {
  var tabs = document.querySelectorAll('[role="tab"]');
  var tabList = document.querySelector('[role="tablist"]'); // Add a click event handler to each tab

  Array.prototype.forEach.call(tabs, function(tab) {
    tab.addEventListener("click", changeTabs);
  });

  var tabFocus = 0;
  tabList.addEventListener("keydown", function (e) {
    // Move right
    if (e.keyCode === 39 || e.keyCode === 37) {
      tabs[tabFocus].setAttribute("tabindex", -1);

      if (e.keyCode === 39) {
        tabFocus++; // If we're at the end, go to the start

        if (tabFocus >= tabs.length) {
          tabFocus = 0;
        } // Move left

      } else if (e.keyCode === 37) {
        tabFocus--; // If we're at the start, move to the end

        if (tabFocus < 0) {
          tabFocus = tabs.length - 1;
        }
      }

      tabs[tabFocus].setAttribute("tabindex", 0);
      tabs[tabFocus].focus();
    }
  });
};

