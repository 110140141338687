var close = function(e) {
  var alert = this.parentNode.parentNode.parentNode;
  document.body.removeChild(alert);
  e.preventDefault();
};

var targets = document.querySelectorAll('.alert__close');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', close);
}
