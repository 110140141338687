var tables = document.querySelectorAll('.table__scroll');

var scroll = function(e) {
  this.parentNode.classList.add('table--scrolled');
  this.removeEventListener('scroll', scroll);
};

for (var i = 0; i < tables.length; i += 1) {
  tables[i].addEventListener('scroll', scroll);
}
