var createFocusTrap = require("focus-trap");

var focusTrapView = createFocusTrap("#header-trap");
var headerLogo = document.querySelector(".header__logo");
var toggleSearch = document.querySelector(".header__toggle--search");

var toggle = function(e) {
  document.body.classList.toggle("menu-active");
  e.preventDefault();

  if (document.body.classList.contains("menu-active")) {
    this.setAttribute("aria-expanded", true);
    focusTrapView.activate();
    headerLogo.setAttribute("tabindex", -1);
    toggleSearch.setAttribute("tabindex", -1);
  } else {
    this.setAttribute("aria-expanded", false);
    focusTrapView.deactivate();
    headerLogo.removeAttribute("tabindex");
    toggleSearch.removeAttribute("tabindex");
  }
};

var target = document.querySelector(".toggle-menu");

if (target) {
  target.addEventListener("click", toggle);
}

//

var subToggle = function(e) {
  this.parentNode.classList.toggle("menu__item--active");
  if (this.parentNode.classList.contains("menu__item--active")) {
    this.setAttribute("aria-expanded", true);
  } else {
    this.setAttribute("aria-expanded", false);
  }
  e.preventDefault();
};

var subTargets = document.querySelectorAll(".menu__sub-toggle");

for (var i = 0; i < subTargets.length; i += 1) {
  subTargets[i].addEventListener("click", subToggle);
}

var subCurrent = document.querySelectorAll(".menu__item--current");

//

for (var i = 0; i < subCurrent.length; i += 1) {
  subCurrent[i].classList.add("menu__item--active");
}

var subCurrentChildren = document.querySelectorAll(
  ".menu__item--current > .menu__sub-toggle"
);
for (var i = 0; i < subCurrentChildren.length; i += 1) {
  subCurrentChildren[i].setAttribute("aria-expanded", true);
}

var subCurrentChildrenActive = document.querySelectorAll(
  ".menu__item--active > .menu__sub-toggle"
);
for (var i = 0; i < subCurrentChildrenActive.length; i += 1) {
  subCurrentChildrenActive[i].setAttribute("aria-expanded", true);
}
