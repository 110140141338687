function animationRun(i, target) {
  setTimeout(function() {
    target.classList.remove('fadeUp');
    target.classList.add('fadeUp--active');
  }, 320 + (i * 320));
}

function animationCheck() {
  var targets = document.querySelectorAll('.fadeUp');
  for (var i = 0, len = targets.length; i < len; i++) {
    var target = targets[i];
    var position = target.getBoundingClientRect().top - window.innerHeight;
    if (position < 0) {
      animationRun(i, target);
    }
  }
}

function animationStart() {
  window.requestAnimationFrame(animationCheck);
}

setInterval(animationStart, 16);